body{
  font-family:"Impact",sans-serif;
  font-size:20px;
  font-weight:700;
  background:#fff;
}
h1{
  font-size:70px;
  @media all and (max-width:768px){
    font-size:50px;
  }
}
.font-headings{
  font-weight:700;
}
.font-slanted{
  transform: rotate(0);
  display: inline-block;
}
.font-shadow{
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  color: #fff;
  position:relative;
  &:before{
      content: attr(data-text);
      color:#000;
      position:absolute;
      right:-5px;
      top:5px;
      z-index:-1
  }
}

header{
  padding:20px 0;
  position:fixed;
  width:100%;
  background: linear-gradient(to bottom, #000 0%, #0000 100%);
  z-index:1;
  .container{
      display:flex;
  }
  .brand{
      font-weight:400;
      font-size:55px;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: black;
      color: #fff;
      position:relative;
      &:before{
          content: attr(data-text);
          color:#000;
          position:absolute;
          right:-4px;
          top:4px;
          z-index:-1
      }
  }
  nav{
      margin-left:auto;
      ul{
          list-style-type:none;
          padding:0;
          margin:0;
          display:flex;
          gap:20px;
          a{
              text-decoration:none;
              font-weight:700;
              color:#fff;
              text-shadow:2px 2px 4px rgba(0,0,0,0.8);
              &:hover{
                color:#fe0000;
                cursor:pointer;
                line-height:20px;
              }
              &.active{
                color:#fe0000;
              }
          }
      }
  }
}
main{
  min-height:100vh;
  display:flex;
  align-items:center;
  color:#000;
  background:url(./images/romebg.jpg) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  @media all and (max-width:768px){
    min-height:auto;
    padding-top:100px;
    padding-bottom:100px;
    background-size: auto 100%;
  }
}

#trollenomics{
  background:url(./images/stonks2.jpg) no-repeat center center;
  background-attachment: fixed;
  background-size:cover;
  position: relative;
  padding:200px 0;
  font-size:30px;
  text-align:center;
  @media all and (max-width:768px){
    padding-top:50px;
    padding-bottom:500px;
    background-size: auto 100%;
  }
  .problem{
      display:block;
      position:absolute;
      bottom:0;
      left:100px;
      height:80%;
      @media all and (max-width:768px){
        left:auto;
        height:initial;
        width: 60%;
      }
  }

  .wrapper{
      margin-left:50%;
      @media all and (max-width:768px){
        margin:auto;
      }
  }

  .item{
      border:1px solid rgba(255,255,255,0.5);
      border-top-color: #fff;
      background:rgba(255,255,255,0.4);
      border-radius:20px;
      padding:20px;
      color:#fff;
      backdrop-filter: blur(5px);
      @media all and (max-width:768px){
        margin-top:30px;
      }
  }
}

.trollolol{
  padding:100px 0;
  text-align:center;
  position:relative;
  background:#fff;
  @media all and (max-width:768px){
    padding:100px 30px;
  }
}

.buy-button{
  display:inline-block;
  border:4px solid #fe0000;
  background:rgba(255,255,255,0.4);
  border-bottom-width:8px;
  backdrop-filter: blur(5px);
  color:#fe0000;
  border-radius:15px;
  text-decoration:none;
  padding:10px 40px;
  font-weight:700;
  position:relative;
  &:active{
      border-bottom-width:4px;
      bottom:-4px;
      margin-bottom:4px;
  }
}

.address{
  border:4px dashed #fe0000;
  color:#fe0000;
  border-radius:15px;
  text-decoration:none;
  padding:10px 15px;
  font-weight:700;
  text-align:left;
  line-height: 23px;
  
  @media all and (max-width:768px){
    word-break: break-all;
    line-height: 35px;
    margin-bottom:20px;
    margin-top:20px;
    font-size:27px;
  }
  span{
      font-size: 15px;
      color:#000;
      display:block;
      @media all and (max-width:768px){
        font-size: 20px;
      }
  }
}

#about{
  text-align:center;
  margin-top:100px;
}

footer{
  text-align:center;
  margin-top:100px;
  strong{
    font-weight:700;
  }
}

.Uniswap{
  > div{
    margin:auto;
    margin-bottom:100px;
  }
}

.text-readable{
  color:#fff;
  text-shadow:2px 2px 4px rgba(0,0,0,0.8)
}

.text-selected{
  background:#fe0000;
  color:#fff;
  text-shadow:2px 2px 4px rgba(0,0,0,0.8);
  padding:2px 5px;
}